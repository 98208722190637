import React, { useState, useEffect } from 'react';
import { uniq, compact, capitalize } from "lodash";
import * as d3 from "d3";

import { Input, Select, Button } from 'antd';
import "antd/dist/antd.min.css";
import Scatterplot from './components/Scatterplot';
const { Search } = Input;
const { Option } = Select;
const selectionStructure = {series:[], set:[], supertype:[], rarity:[], types:[], subtypes:[]};
const metricsList=["hp","number of cards","series","rarity","set","types","subtypes","maxDamage"];

function App() {
  const [pokemonCards, setPokemonCards] = useState([]);
  const [currentPokemonCards, setCurrentPokemonCards] = useState([]);
  const [lists, setLists] = useState(selectionStructure);
  const [visibleLists, setVisibleLists] = useState(selectionStructure);
  const [selectionLists, setSelectionLists] = useState(selectionStructure);
  const [searchText, setSearchText] = useState("");
  const [metrics, setMetrics] = useState({x:metricsList[0],y:metricsList[1]});

  const [width, setWidth] = useState(window.innerWidth);
  const [dim,setDim] = useState({ width:window.innerWidth>=768?window.innerWidth-300-20:window.innerWidth-20, height:500 })

  useEffect(() => {      

    fetch('https://raw.githubusercontent.com/thetylerwolf/pokemon_cards/master/ash_collection.json')
    .then(response => response.json())
      .then(cards => {

        fetch('https://raw.githubusercontent.com/thetylerwolf/pokemon_cards/master/sets/en.json')
        .then(response => response.json())
        .then(sets => {


        cards.sort((a,b)=> a.id.localeCompare(b.id));

        cards.forEach((d,i) => {
          const setId = d.id.split('-')[0];
          const setObject = sets.find(d=>d.id===setId);
          d["hp"] = d.hp?+d.hp:null;
          d["set"] = setId + " - " + setObject.name;
          d["rarity"] = d.rarity?d.rarity:"Undefined";
          d["series"] = setObject.series;
          d["subtypes"] = d.subtypes?d.subtypes.sort((a,b)=>a.localeCompare(b)).join(" & "):"Undefined";
          d["types"] = d.types?d.types.sort((a,b)=>a.localeCompare(b)).join(" & "):"Undefined";
          d["subtypesNumber"] = d.subtypes?d.subtypes.length:null;
          d["typesNumber"] = d.types?d.types.length:null;
          d["rulesNumber"] = d.rules?d.rules.length:null;
          d["attacksNumber"] = d.attacks?d.attacks.length:null;
          d["maxConvertedEnergyCost"] = d.attacks?d3.max(d.attacks, attack=>+attack.convertedEnergyCost):null;
          d["maxDamage"] = d.attacks?d3.max(d.attacks, attack=>+attack.damage):null;
          d["resistancesNumber"] = d.resistances?d.resistances.length:null;
          d["weaknessesNumber"] = d.weaknesses?d.weaknesses.length:null;
          d["number of cards"] = 1;
        });
        const uniqueCards = cards.reduce(
          (acc, obj) => {
            const listIds = acc.map(d=>d.id);
            if (listIds.includes(obj.id)){
              acc.find(d=>d.id===obj.id)["number of cards"]++;
            }
            else
              acc.push(obj);
            return acc;
          },
          []
        );
        setLists({
          set:uniq(uniqueCards.map(card=>card.set)).sort((a,b)=>a.localeCompare(b)),
          series:uniq(uniqueCards.map(card=>card.series)).sort((a,b)=>a.localeCompare(b)),
          supertype:uniq(uniqueCards.map(card=>card.supertype)).sort((a,b)=>a.localeCompare(b)),
          rarity:compact(uniq(uniqueCards.map(card=>card.rarity))).sort((a,b)=>a.localeCompare(b)),
          types:compact(uniq(uniqueCards.map(card=>card.types))).sort((a,b)=>a.localeCompare(b)),
          subtypes:compact(uniq(uniqueCards.map(card=>card.subtypes))).sort((a,b)=>a.localeCompare(b))
        })
        setPokemonCards(uniqueCards);
        setCurrentPokemonCards(uniqueCards);
      });
    });
  },[]);

  useEffect(() => {
    let newCurrentPokemonCards = [...pokemonCards]
    Object.keys(selectionLists).forEach(list=>{
      newCurrentPokemonCards = newCurrentPokemonCards.filter(d=>
        selectionLists[list].includes(d[list]) || selectionLists[list].length === 0
      )
    })
    newCurrentPokemonCards = newCurrentPokemonCards.filter(d => d.name.toLowerCase().includes(searchText.toLowerCase()));

    setVisibleLists({
      set:uniq(newCurrentPokemonCards.map(card=>card.set)),
      series:uniq(newCurrentPokemonCards.map(card=>card.series)),
      supertype:uniq(newCurrentPokemonCards.map(card=>card.supertype)),
      rarity:compact(uniq(newCurrentPokemonCards.map(card=>card.rarity))),
      types:compact(uniq(newCurrentPokemonCards.flatMap(card=>card.types))),
      subtypes:compact(uniq(newCurrentPokemonCards.flatMap(card=>card.subtypes)))
    })
    setCurrentPokemonCards(newCurrentPokemonCards);
  },[searchText,selectionLists,pokemonCards]);


  const applyFilter = (newValue) => {
    setSearchText(newValue);
  };

  const handleChange = (value,listName) => {
    const newSelectionsLists = {...selectionLists};
    newSelectionsLists[listName] = value;
    setSelectionLists(newSelectionsLists);
  };

  
  return (
    <div>
          <div style={{padding:20, width:width>=768?300:'100%', position:width>=768?'fixed':'relative'}}>
            <div style={{textAlign:"center"}}>
              <img alt="logo"src="/pokemon.png" style={{ width:"50%", height:"50%"}}></img>
            </div>
            <div>Search
              <Search
                style={{width:"100%"}}
                onInput = { e => applyFilter(e.target.value) }
                value={searchText}
                placeholder="Card Name"
              />
            </div>
            {Object.keys(selectionLists).map(listName=>
              <div key={listName}>{capitalize(listName)}
                <Select
                  style={{width:"100%"}}
                  mode="multiple"
                  allowClear
                  value={selectionLists[listName]}
                  onChange={(value)=>handleChange(value,listName)}
                  maxTagCount= 'responsive'
                >
                  {lists[listName].map(elem=>
                    <Option key={elem} value={elem}>
                      {visibleLists[listName].includes(elem)?
                        <div style={{opacity:1}}>{elem}</div>:
                        <div style={{opacity:0.3}}>{elem}</div>
                      }
                    </Option>
                  )}
                </Select>
              </div>
            )}
            <div style={{textAlign:"right", marginTop:5}}>
              <Button
                onClick={()=>{setSelectionLists(selectionStructure);setSearchText("")}}>
                  Clear
              </Button>
            </div>
          </div>

          <div
            style={{
              padding:10,
              textAlign:"center",
              width:width>=768?width-300:'100%',
              position:width>=768?'absolute':'relative',
              marginLeft:width>=768?300:0
              }}>
            <div style={{ width:"100%"}}>
              <div style={{display:"inline-block",width:"50%"}}>
                <span>X Axis </span>
                <Select
                  style={{width:"70%"}}
                  value={metrics.x}
                  onChange={(value)=>setMetrics({x:value, y:metrics.y})}>
                  {metricsList.map(metric=>
                    <Option key={metric} value={metric}>
                    {metric}
                    </Option>
                  )}
                </Select>
              </div>
              <div style={{display:"inline-block",width:"50%"}}>
                <span>Y Axis </span>
                <Select
                  style={{width:"70%"}}
                  value={metrics.y}
                  onChange={(value)=>setMetrics({x:metrics.x,y:value})}>
                  {metricsList.map(metric=>
                    <Option key={metric} value={metric}>
                    {metric}
                    </Option>
                  )}
                </Select>
              </div>
            </div>
            <Scatterplot
              dim = {dim}
              data = {currentPokemonCards}
              metrics = {metrics}
            />

          </div>
    </div>
  );
}

export default App;
