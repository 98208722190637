import React, { useState, useEffect, useRef } from "react";
import Cards from "./Cards";
import { uniq } from "lodash";
import AxisX from "./AxisX";
import AxisY from "./AxisY";
import * as d3 from "d3";

const margin = {
  top: 30,
  bottom: 90,
  left: 100,
  right: 20,
};
const domains = ["series","subtypes","rarity","set","types"];

const Scatterplot = props => {

  const {dim, data, metrics} = props;
  const [filtered, setFiltered] = useState(data);
  const [grouped, setGrouped] = useState([]);
  const [selected, setSelected] = useState([]);
  const d3Container = useRef(null);
  const xScale = useRef(null);
  const yScale = useRef(null);
  const bandwidth= useRef({x:0,y:0});

  useEffect(() => {
    const svg = d3.select(d3Container.current);
    const cleanData = data.filter(d=>
      (d[metrics.x]!==null && d[metrics.x]!==undefined) && 
      (d[metrics.y]!==null && d[metrics.y]!==undefined))
  
    let counter=0;
    const newGroupedData = cleanData.reduce((acc,obj)=>{
      
      const listCombos = acc.map(d=>d.combo);
      const combo = obj[metrics.x].toString() + " - " + obj[metrics.y].toString();
      if (listCombos.includes(combo)){
        const objMatch = acc.find(d=>d.combo===combo);
        objMatch.count= objMatch.count + obj["number of cards"];
        objMatch.id.push(obj.id);
      }
      else{
        const objReduced = {
          id: [obj.id],
          combo: combo,
          count: obj["number of cards"],
          internalId:"id"+counter.toString()
        };
        counter++;
        objReduced[metrics.x]=obj[metrics.x];
        objReduced[metrics.y]=obj[metrics.y];
        acc.push(objReduced);
      }
      return acc;
    },[])

    setGrouped(newGroupedData);

    const size = {
      max:d3.max(newGroupedData, (d) => d.count),
      min:d3.min(newGroupedData, (d) => d.count)
    }
  
    const sizeScale = d3
    .scaleRadial()
    .domain([size.min, size.max])
    .range([4, 15])
  
    const max = {
      x: d3.max(newGroupedData, (d) => d[metrics.x]),
      y: d3.max(newGroupedData, (d) => d[metrics.y])
    }
  

    if (domains.includes(metrics.x)){
      const domainX = uniq(newGroupedData.flatMap((d) => d[metrics.x])).sort((a,b)=>a.localeCompare(b));
      xScale.current = d3
        .scaleBand()
        .domain(domainX)
        .range([margin.left, dim.width - margin.left - margin.right])
        bandwidth.current.x=xScale.current.bandwidth();
    }
    else{
      xScale.current = d3
        .scaleLinear()
        .domain([0, max.x])
        .range([margin.left, dim.width - margin.left - margin.right])
        bandwidth.current.x=0;
    }


    if (domains.includes(metrics.y)){
      const domainY = uniq(newGroupedData.flatMap((d) => d[metrics.y])).sort((a,b)=>a.localeCompare(b));
      yScale.current = d3
        .scaleBand()
        .domain(domainY)
        .range([margin.top, dim.height - margin.top - margin.bottom])
      bandwidth.current.y=yScale.current.bandwidth();
    }
    else{
      yScale.current = d3
        .scaleLinear()
        .domain([max.y,0])
        .range([margin.top, dim.height - margin.top - margin.bottom])
        bandwidth.current.y=0;
      }

    svg.select("rect").on("click", (event,d) => {
      setSelected([])
      svg.selectAll("circle")
      .style("fill","#558")
    })
    svg
      .select(".bubbles")
      .selectAll("circle")
      .data(newGroupedData, (d) => d.internalId)
      .join("circle")
      .attr("class",d=>d.internalId)
      .style("fill", "#558" )
      .style("opacity",0.8)
      .on("click", (event,d) => {
        setSelected(d.id)
        svg.selectAll("circle")
        .style("fill","#558")
        svg.selectAll("." + d.internalId)
        .style("fill","#F05")
   
      })
      .on("mouseover", (event, d) => 
        svg.selectAll("." + d.internalId)
        .style("opacity",1)
        )
      .on("mouseleave", (event, d) => 
      svg.selectAll("." + d.internalId)
      .style("opacity",0.8)
        )
        .attr("stroke-width", 0)
        .attr("r", d=>sizeScale(d.count))
        .transition()
        .duration(1000)
        .attr("cx", (d) => xScale.current(d[metrics.x]) + bandwidth.current.x*0.5)
        .attr("cy", (d) => yScale.current(d[metrics.y]) + bandwidth.current.y*0.5)

        svg
      .select(".bubbles")
      .selectAll("circle")
      .selectAll(".selected")
      .style("fill","#F05")
      .style("opacity",1)

  },[data,metrics,dim])

  useEffect(() => {
    setSelected([])
    setFiltered(data)
},[data,metrics])

useEffect(() => {
  if(selected.length===0)
    setFiltered(data)
  else
    setFiltered(data.filter(e => selected.includes(e.id)));
},[selected,data])
  return (<div style={{marginTop:10}}>
      <h3>{grouped.length} group of cards represented</h3>
      <h3>{d3.sum(grouped,d=>d.count)} cards</h3>
        <svg
          x={margin.left}
          width={dim.width} height={dim.height} ref={d3Container}>
          <rect style={{
            fill:"#fff",
width:dim.width, height:dim.height
          }}></rect>
          {xScale.current?
          <AxisX
            xScale={xScale.current}
            dims={dim}
            margin={margin}
          />:null}
{xScale.current?
          <AxisY
            yScale={yScale.current}
            dims={dim}
            margin={margin}
          />:null}
          <g className="bubbles"></g>
        </svg>
        <Cards
          data = {filtered}
        />
      </div>
  );
};

export default Scatterplot;