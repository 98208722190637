import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const AxisX = props=>{
  const d3ContainerRef = useRef(null);

  const {dims,margin,xScale} = props;

  useEffect(() => {
    const chart = d3.select(d3ContainerRef.current).select('g');
    const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
    chart.call(xAxis);//.attr('font-family', utils.fontFamily);
    chart
    .selectAll('text')
    .attr('y', 0)
    .attr('x', -8)
    .attr('dy', '.35em')
    .attr('transform', 'rotate(-90)')
    .style('fill-opacity', 0.7)
    .style('font-size', 9)
    .style('text-anchor', 'end')


    chart
    .selectAll('.tick')
    .selectAll('line')
    .attr('stroke', 'currentColor')
    .attr('stroke-opacity', 0.1)
    .attr('y1',0)
    .attr('y2', -dims.height+margin.top+margin.bottom+margin.top);

    d3.select(d3ContainerRef.current)
    .select('g')
    .transition()
    .duration(500)
    .attr("transform","translate(0," + (dims.height-margin.top-margin.bottom)+ ")")

  }, [dims,margin,xScale]);

  useEffect(() => {
    d3.select(d3ContainerRef.current)
      .append('g')
      
  }, []);

  return (
    <svg
      ref={d3ContainerRef}
      x={0}
      y={0}
      width={dims.width - margin.left}
      height={dims.height}
    />
  );
}

export default AxisX;
