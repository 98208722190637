import React from "react";
import { Badge } from "antd";
import * as d3 from "d3";

const Cards = props => {

  const {data} = props;
  data.sort((a,b)=>b["number of cards"]-a["number of cards"]);

  return (
    <div>
      <h3>{data.length} different cards filtered</h3>
      <h3>{d3.sum(data,d=>d["number of cards"])} cards in total</h3>
      {data.map((card,cardi)=>
      <div
      key={card.id}
      style={{
        margin:16,
        padding:8,
        display:'inline-block',
        width:245*0.6+24}
    }
       >
      <Badge
      count={card["number of cards"]>1?card["number of cards"]:0}
      style={{backgroundColor: '#000000'}}>
      <div 
        
        style={{
            padding:8,
            width:245*0.6+20,
            height:270,
            borderStyle:'solid', borderWidth:0, borderRadius:10, boxShadow:"3px 3px 4px #0002"}}>
        
        <div>
          <h5>{card.name}</h5>
          <h6>{card.id}</h6>
          <img
            alt={card.id} 
            style={{ width:245*0.6, height:342*0.6}}
            src={card.images.small}>
          </img>
        </div>
         
         </div>
         </Badge>
         </div>
      )}
    </div>
  );
};

export default Cards;