import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const AxisY = props=>{
  const d3ContainerRef = useRef(null);

  const {dims,margin,yScale} = props;

  useEffect(() => {
    const chart = d3.select(d3ContainerRef.current).select('g');
    const yAxis = d3.axisLeft(yScale)

    chart.call(yAxis);

  chart
    .selectAll('text')
    .style('font-size', 10)
    .style('font-weight', 300)
    .style('color', '#000');

  chart
    .selectAll('.tick')
    .selectAll('line')
    .attr('stroke', 'currentColor')
    .attr('stroke-opacity', 0.1)
    .attr('x1', 0)
    .attr('x2', dims.width - margin.left - margin.right);


  }, [dims,margin,yScale]);

  useEffect(() => {
    d3.select(d3ContainerRef.current)
      .append('g')
      .attr('transform','translate('+margin.left+',0)')
  }, []);

  return (
    <svg
      ref={d3ContainerRef}
      x={0}
      y={0}
      width={dims.width - margin.left-margin.right}
      height={dims.height}
    />
  );
}

export default AxisY;
